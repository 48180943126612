import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styles from "./index.module.css"

import Container from "../components/container.js"

const Index = () => {
	
	const data = useStaticQuery(graphql`
		query Images {
			protest_span: file(relativePath: {eq: "belmont_protest_1.jpg"}) {
				id
				childImageSharp {
					fluid (maxWidth: 1600){
						...GatsbyImageSharpFluid
					}
				}
			},
			insta_logo: file(relativePath: {eq: "insta_logo_alt.png"}) {
				id
				childImageSharp {
					fixed (width: 105) {
						...GatsbyImageSharpFixed
					}
				}
			},
			facebook_logo: file(relativePath: {eq: "facebook_logo_alt.png"}) {
				id
				childImageSharp {
					fixed (width: 100) {
						...GatsbyImageSharpFixed
					}
				}
			},
			coworker_logo: file(relativePath: {eq: "coworker_logo.png"}) {
				id
				childImageSharp {
					fixed (width: 100) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	
	
	return (
		<div>
			<Container>
				<div className={styles.focusBox}>
					<a href="https://www.coworker.org/petitions/moc-workers-united-for-hazard-pay-2">
						<div className={styles.focusItem}>
							<h3> Hazard Pay Petition </h3>
							<p className={styles.centered}>
								<Img fixed={data.coworker_logo.childImageSharp.fixed} />
							</p>
						</div>
					</a>
					
					<a href="https://instagram.com/mocblm">
						<div className={styles.focusItem}>
							<h3> MOC BLM Instagram </h3>
							<p className={styles.centered}>
								<Img fixed={data.insta_logo.childImageSharp.fixed} />
							</p>
						</div>
					</a>
					
					<a href="https://facebook.com/mocblm">
						<div className={styles.focusItem}>
							<h3> Facebook Page </h3>
							<p className={styles.centered}>
								<br/>
								<Img fixed={data.facebook_logo.childImageSharp.fixed} />
							</p>
						</div>
					</a>
				</div>
				
				<h1>
					Why are We Protesting? How is Market of Choice Complicit in Racism?
				</h1>
				<p>
					Market of Choice released a statement on June 12th in support of the Black Lives Matter movement. In it, CEO Rick Wright stated, "We believe Black Lives Matter. We support our Black team members, customers, and vendors and do not tolerate racial bias, discrimination, or injustice at our stores or in our communities." Wanting to show support for the movement and following Rick Wrights lead, workers across the company wore masks and pins that said Black Lives Matter. In Eugene, Corvallis, and Portland employees walked out when they were told to remove the messaging. Some of us returned to work for financial reasons while others pushed the company to go beyond the performative activism. Consitently, Market of Choice told us the messaging does not provide and "safe and comfortable" shopping experience. Many of us tried to get in contact with corporate HR after conversations with store level management hit a dead end. After weeks of no clear response or plan of action from corporate, we made the move to push the strike further and hold informational pickets in front of the stores. Other employees came forward to share their frustrations with the way discrimination is handled within the company. 
				</p>
				
				<h1>
					Why We're Organizing
				</h1>
				<p>
					We're Market of Choice (MOC) employees fighting for racial justice, equality in the workplace, meaningful anti-harassment, and economic justice.
					Our collective formed in late June 2020 when MOC management prohibited wearing BLM pins and face masks, sending us home for "refusing to comply with the dress code". We've conducted protests accross the state, in Eugene, Corvallis, and Portland & the surrounding areas. Our first agenda item is to hold Market of Choice accountable to the public statements they've made in support of Black Lives Matter.
				</p>
				
				<Img fluid={data.protest_span.childImageSharp.fluid} />
				<p>
					June 30th protest outside the Belmont Market of Choice.
				</p>
				
				
			</Container>
		</div>
	)
}

export default Index
